<template>
  <div class="videoPlayerContainer">
    <button
      @click="$parent.stopVideo()"
      class="btn fa fa-arrow-left text-white position-absolute drop-shadow-darker bring-to-front rounded-circle"
    ></button>
    <video ref="videoPlayer" class="video-js vjs-theme-city"></video>
  </div>
</template>

<script>
import videojs from "video.js";
import "video.js/dist/video-js.css";

import Media from "@/api/Media";

export default {
  name: "VideoPlayer",

  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    program: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      player: null,
      reportedTime: 0,
      current: 0,
      reportFrequency: 30, // seconds
    };
  },

  mounted() {
    if (!this.player) {
      this.mountPlayer();
    }
  },
  updated() {},
  destroyed() {
    document.body.classList.remove("overflow-hidden");
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },

  methods: {
    mountPlayer: function () {
      if (this.program.format == "series") {
        this.current = this.program.media.findIndex(
          (m) => m.id === this.options.media.id
        );
        if (this.program.media[this.current].title) {
          this.$updateMeta.title({
            title: this.program.media[this.current].title,
          });
        }
      }

      this.player = videojs(
        this.$refs.videoPlayer,
        this.options,
        this.onPlayerReady
      );

      this.player.playsinline(true);
      document.body.classList.add("overflow-hidden");
    },

    onPlayerReady() {
      this.player.on("timeupdate", () => {
        let currentTime = this.player.currentTime();
        if (currentTime > this.reportedTime + this.reportFrequency) {
          this.reportedTime = currentTime;
          this.updateTimestamp(currentTime);
        }
      });
      this.player.on("ended", () => {
        this.playNext();
      });
    },

    updateTimestamp(time) {
      new Media({ id: this.options.media.id }).reproductionLogs().attach({
        time,
      });
    },
    playNext() {
      if (this.program.format == "series") {
        this.current = this.current + 1;
        let media = this.program.media[this.current];

        if (media.title) {
          this.$updateMeta.title({ title: media.title });
        }

        if (media) {
          let options = {
            autoplay: true,
            controls: true,
            fullscreen: true,
            aspectRatio: "16:9",
            poster: null,
            media: null,
            sources: [
              {
                src: null,
                type: "application/vnd.apple.mpegurl",
              },
            ],
          };
          options.poster = media.img_poster;
          options.media = media;
          options.sources[0] = {
            type: "application/vnd.apple.mpegurl",
            src: media.hls,
          };
          this.reportedTime = 0;
          this.player.src(options.sources);
          this.options = options;
          this.player.play();
        } else {
          this.$parent.playBack = false;
        }
      } else {
        this.$parent.playBack = false;
      }
    },
  },
};
</script>
