import Model from "./Model";
import MediaReproductionLog from "./MediaReproductionLog";

export default class Category extends Model {
  resource() {
    return "media";
  }

  reproductionLogs() {
    return this.hasMany(MediaReproductionLog);
  }
}
